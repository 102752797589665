<template>
  <div class="about-us">
    <div class="content">
      <div class="content-left">
        <p>
          &nbsp;&nbsp;&nbsp;At Paragon Auto Detail, I understand that your car
          is more than just a means of transportation; it's a reflection of your
          personality and lifestyle. As a one-person operation, I give your
          vehicle the individualized care and attention it deserves, treating it
          as if it were my own.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;From exterior paint correction and protection to
          interior rejuvenation and odor removal, my meticulous approach ensures
          that your car will shine like new. I take pride in using the latest
          techniques and premium products, guaranteeing exceptional results for
          every customer.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;Transparency and customer satisfaction are at the
          core of my business values. I believe in building long-lasting
          relationships with my clients based on trust and reliability.
        </p>
        <img
          src="../assets/images/logos-png/Paragon Auto Detail WITH BORDER.png"
          alt="Paragon Auto Detail Logo"
          class="filler-image"
        />
      </div>
      <div class="content-right">
        <transition name="fade">
          <img
            v-if="showImage"
            src="../assets/images/van_front.jpeg"
            alt="Paragon Auto Detail Logo"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "AboutMe",
  data() {
    return { showImage: true };
  },
};
</script>

<style scoped>
.about-us {
  background-color: inherit;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}

.content-left {
  width: 30%;
  margin-right: 20px;
}
.content-right {
  width: 70%;
}
img {
  width: 100%;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@media (max-width: 1190px) {
  .content {
    flex-direction: column-reverse;
    margin-top: 1rem;
    align-items: center;
  }
  .content-left {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .content-right {
    width: 100%;
    justify-content: left;
  }
  .filler-image {
    display: none;
  }
}
</style>
