<template>
  <div class="quote-container">
    <h1 class="info-container-title">Get A Quote</h1>
    <div class="contact-us-form-container info-container-content">
      <ContactUsForm />
    </div>
  </div>
  <div class="info-map-container">
    <div class="info-container-content">
      <div class="info-container">
        <div class="links">
          <div class="logos">
            <a
              href="https://www.google.com/maps/place/Paragon+Auto+Detail+LLC/@41.519698,-86.1842032,10z/data=!4m12!1m5!3m4!1s0x0:0x377f603124847f4c!2sParagon+Auto+Detail+LLC!11m1!2e1!3m5!1s0xa10f3f5c8b31bcc9:0x377f603124847f4c!8m2!3d41.519698!4d-86.1842032!16s%2Fg%2F11tg5z9r28?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../../assets/images/google-black.png"
                alt="Google Logo"
                width="40"
            /></a>
            <a
              href="https://www.facebook.com/profile.php?id=100088403297919"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../../assets/images/facebook-black.png"
                alt="Facebook Logo"
                width="40"
            /></a>
          </div>
          <p class="phone-number no-margin">
            <a href="tel:+15742096570">574-209-6570</a>
          </p>
          <p class="location no-margin">Bremen, IN</p>
        </div>
        <div class="text-container">
          <p class="no-margin">
            Fill out the form and I'll contact you ASAP. You can also reach out
            to us on Facebook or Google, or call to get an estimate.
          </p>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d382372.8866653707!2d-86.1842032!3d41.519698!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa10f3f5c8b31bcc9%3A0x377f603124847f4c!2sParagon%20Auto%20Detail%20LLC!5e0!3m2!1sen!2sus!4v1720365980839!5m2!1sen!2sus"
        width="100%"
        height="275px"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </div>
  </div>
</template>

<script>
import ContactUsForm from "./ContactUsForm.vue";

export default {
  components: {
    ContactUsForm,
  },
};
</script>

<style scoped>
.quote-container {
  max-width: 50vw;
  min-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
}

.info-container-title {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.info-map-container {
  width: 50vw;
  display: flex;
  flex-direction: column;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 20px;
}

.text-container {
  width: 75%;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.phone-number,
a {
  white-space: nowrap;
  color: black;
  text-decoration: none;
}
.phone-number:hover,
a:hover {
  text-decoration: underline;
}
.phone-number {
  margin-top: 8px;
}
.location {
  margin-top: 0;
  font-size: 16px;
}

@media (max-width: 1190px) {
  .quote-container {
    width: 100%;
    max-width: 100%;
  }

  .contact-us-form-container {
    margin-top: 1rem;
  }

  .info-container-title {
    height: auto;
  }

  .info-container {
    width: 100%;
  }

  .info-map-container {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
