<template>
  <SpeedInsights />
  <div id="home">
    <div class="nav-bar-container">
      <NavBar />
    </div>

    <div class="content">
      <div class="carousel-container">
        <ImageCarousel :images="images" class="image-carousel" />
      </div>
      <div class="get-a-quote-container">
        <QuoteSection />
      </div>
      <div class="about-me-container">
        <h1>About Me</h1>
        <AboutMe />
      </div>
    </div>
    <div class="footer-container">
      <FooterContainer />
    </div>
  </div>
</template>

<script>
import { SpeedInsights } from "@vercel/speed-insights/vue";
import AboutMe from "./AboutMe.vue";
import ImageCarousel from "./ImageCarousel.vue";
import NavBar from "./NavBar.vue";
import QuoteSection from "./QuoteSection/QuoteSection.vue";
import FooterContainer from "./FooterContainer.vue";

export default {
  name: "HomePage",
  metaInfo() {
    return {
      title: "Paragon Auto Detailing LLC",
    };
  },
  components: {
    SpeedInsights,
    AboutMe,
    ImageCarousel,
    NavBar,
    QuoteSection,
    FooterContainer,
  },
  data() {
    return {
      images: Array.from(
        [
          "demon",
          "golf",
          "lexus",
          "jeep_wrangler_unlimited",
          "2500HD",
          "jeep_grand_cherokee",
          "harley",
          "wrx",
          "charger",
        ],
        (vehicleNickname) =>
          require(`../assets/images/sides/${vehicleNickname}.jpeg`)
      ),
    };
  },
};
</script>

<style>
@import "../assets/variables/global.css";

#home {
  font-family: "Helvetica", Arial, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--paragon-red);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  min-height: 100vh;
}

body {
  font-family: "Avenir", "Helvetica", "Arial", monospace;
}
h1 {
  margin: 0;
  font-size: 90px;
  text-align: left;
}
h2 {
  font-size: 64px;
}
p {
  text-align: left;
  font-size: 20px;
}

.content {
  background-color: var(--paragon-red);
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-content: center;
  background-image: url("../assets/images/grayscale-water-on-windshield.png");
  background-size: cover;
  background-position: center;
  padding: 5rem;
  height: 90vh;
  max-height: 90vh;
}

.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--paragon-red);
  padding: 2rem;
}

.get-a-quote-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: var(--paragon-gold);
  padding: 2rem;
}

.title {
  font-size: 64px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.nav-bar-container {
  width: 100vw;
}

.footer-container {
  width: 100vw;
}

.no-margin {
  margin: 0;
}

@media (max-width: 1190px) {
  h1 {
    margin: 0;
    margin-top: 1rem;
    font-size: 63px;
    text-align: left;
  }
  h2 {
    font-size: 48px;
  }

  .nav-bar-container {
    width: 100vw;
  }

  .carousel-container {
    height: fit-content;
    padding: 2rem;
  }

  .get-a-quote-container {
    flex-direction: column;
    padding: 0.5rem;
    gap: 2rem;
  }

  .about-me-container {
    padding: 0.5rem;
  }
}
</style>
