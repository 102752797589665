<template>
  <form @submit.prevent="submitForm">
    <div class="input-group">
      <input
        class="half-width"
        type="text"
        id="firstName"
        v-model="formData.firstName"
        placeholder="First Name*"
        required
      />
      <input
        class="half-width"
        type="text"
        id="lastName"
        v-model="formData.lastName"
        placeholder="Last Name*"
        required
      />
    </div>
    <div class="input-group">
      <input
        class="half-width"
        type="email"
        id="email"
        v-model="formData.email"
        placeholder="Email*"
        required
      />
      <input
        class="half-width"
        type="tel"
        id="phoneNumber"
        v-model="formData.phoneNumber"
        placeholder="Phone Number*"
        required
        pattern="(\d{3}-?\d{3}-?\d{4}|\d{10})"
        title="Phone number must be in the format xxx-xxx-xxxx or xxxxxxxxxx"
      />
    </div>
    <div class="input-group">
      <textarea
        class="additional-notes"
        id="additional-notes"
        placeholder="Job Description"
        v-model="formData.additionalNotes"
        rows="5"
      />
    </div>
    <button type="submit">Submit</button>
  </form>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "ContactUsForm",
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        additionalNotes: "",
      },
    };
  },
  methods: {
    async submitForm() {
      const toast = useToast();
      try {
        let response = await fetch("/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: "dylanh.3006@gmail.com",
            subject:
              this.formData.firstName +
              " " +
              this.formData.lastName +
              " has submitted a quote request",
            text: `First Name: ${this.formData.firstName}\nLast Name: ${this.formData.lastName}\nEmail: ${this.formData.email}\nPhone Number: ${this.formData.phoneNumber}\nJob Description: ${this.formData.additionalNotes}`,
          }),
        });
        if (response.ok) {
          this.status = "Email sent successfully";
        } else {
          this.status = "Failed to send email";
        }
      } catch (error) {
        console.error("Error sending email: ", error);
        this.status = "Failed to send email";
      }

      try {
        const response = await fetch("/api/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        const data = await response.json();
        if (response.ok) {
          toast.success("Submission successful");
        } else {
          toast.error(`Error: ${data.error}`);
        }
      } catch (error) {
        toast.error("Submission failed");
      }
    },
  },
};
</script>

<style scoped>
form {
  max-width: 90%;
  min-width: 90%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  background: white;
}
.title {
  font-size: 64px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.required {
  color: red;
  margin-left: 0.2rem;
}
input,
textarea {
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  resize: none;
  background: #f8f9fa;
}
button {
  padding: 10px 20px;
  background: black;
  border-radius: 8px;
  border: none;
  color: white;
  cursor: pointer;
}
button:hover {
  background: black;
}
.half-width {
  width: 49%;
}
.input-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.additional-notes {
  max-width: 100%;
  min-width: 100%;
}

@media (max-width: 1190px) {
  form {
    max-width: 100%;
  }
}
</style>
