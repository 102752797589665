<template>
  <div class="carousel">
    <div
      class="carousel-inner"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div v-for="(image, index) in images" :key="index" class="carousel-item">
        <img :src="image" :alt="'Image ' + index" />
      </div>
    </div>
    <button class="carousel-control left" @click="prevImage">‹</button>
    <button class="carousel-control right" @click="nextImage">›</button>
  </div>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoSlide() {
      this.timer = setInterval(this.nextImage, 5000);
    },
    stopAutoSlide() {
      clearInterval(this.timer);
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.stopAutoSlide();
      this.startAutoSlide();
    },
    prevImage() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
      this.stopAutoSlide();
      this.startAutoSlide();
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  overflow: hidden;
  aspect-ratio: 4 / 3;
  border: 2px solid var(--paragon-gold);
  border-radius: 5px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  scroll-snap-align: start; /* Snap images to the start of the carousel */
}

.carousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
}

.carousel-control.left {
  left: 10px;
  z-index: 0;
}

.carousel-control.right {
  right: 10px;
  z-index: 0;
}
button {
  height: fit-content;
  width: auto;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .carousel-control.left,
  .carousel-control.right {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .carousel-control.left,
  .carousel-control.right {
    font-size: 14px;
  }
}
</style>
