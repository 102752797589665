<template>
  <nav class="navbar">
    <!-- <div class="nav-left">
      <button class="menu-toggle" @click="toggleMenu">☰</button>
    </div> -->
    <div class="nav-center">
      <img src="@/assets/logo.png" alt="Logo" class="logo" />
    </div>
    <!-- <div class="nav-right" :class="{ open: menuOpen }">
      <ul class="nav-links">
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact</a></li>
      </ul>
    </div> -->
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style scoped>
@import "../assets/variables/global.css";

.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--paragon-black);
  color: white;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.nav-left,
.nav-right {
  flex: 1;
}

.nav-center {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: calc(var(--navbar-height) - 20px);
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar {
    /* justify-content: left; */
  }

  .nav-right {
    position: absolute;
    top: var(--navbar-height);
    right: 0px;
    width: 100%;
    background-color: #333;
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .nav-right.open {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
  }

  .logo {
    height: 5rem;
  }
}
</style>
