<template>
  <nav class="footer">
    <div class="footer-center">
      <p>Copyright ©2024 Paragon Auto Detail LLC. All Rights Reserved.</p>
    </div>
  </nav>
</template>

<script>
export default {
  name: "FooterContainer",
};
</script>

<style scoped>
@import "../assets/variables/global.css";

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--paragon-black);
  color: white;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

p {
  margin: 0;
  font-size: 12px;
}
</style>
