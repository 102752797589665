<template>
  <div class="admin-page">
    <button @click="downloadCSV">Download User Submissions</button>
  </div>
</template>

<script>
export default {
  name: "AdminPage",
  methods: {
    async downloadCSV() {
      const response = await fetch("/api/export");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "contact_us_submissions.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>
.admin-page {
  padding: 20px;
}
</style>
